<template>
  <div class="job-responsibilities-current">
    <h4 class="job-responsibilities-current__title">{{ current.name }}</h4>
    <p class="job-responsibilities-current__text">{{ current.final_product }}</p>
    <router-link
      :to="{ name: 'RegulationPage', params: { regulation_id: current.regulation.id } }"
      :target="isGlobalMobileMode ? '_self' : '_blank'"
      class="job-responsibilities-current__regulation"
      v-if="current.regulation"
    >
      <span class="icon">
        <svg
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.8327 34.6875H6.16602V2.3125H23.1243L30.8327 10.0208V34.6875Z"
            fill="#F1F1FF"
          />
          <path d="M29.6764 10.7917H22.3535V3.46875L29.6764 10.7917Z" fill="#DDDDFF" />
          <path d="M12.334 16.1875H25.4382V17.7292H12.334V16.1875Z" fill="#DDDDFF" />
          <path d="M12.334 19.271H22.3548V20.8127H12.334V19.271Z" fill="#DDDDFF" />
          <path d="M12.334 22.354H25.4382V23.8957H12.334V22.354Z" fill="#DDDDFF" />
          <path d="M12.334 25.4375H22.3548V26.9792H12.334V25.4375Z" fill="#DDDDFF" />
        </svg>
      </span>
      <div class="content">
        <span class="regulation-name">{{ current.regulation.name }}</span>
        <span class="regulation-date">{{
          convertDate(current.regulation.datetime_of_creation)
        }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
import { format } from "date-fns";
import { ru } from "date-fns/locale";

export default {
  name: "JobResponsibilitiesCurrent",
  mixins: [],
  props: {
    current: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // Форматирование даты
    convertDate(date) {
      const data = format(new Date(date), "dd MMM yyyy HH:mm", {
        locale: ru
      });
      return data;
    }
  },
  computed: {
    isGlobalMobileMode() {
      return this.$store.getters.getGlobalMobileMode;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.job-responsibilities-current {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 0 0 10px 0;
    padding: 0;

    color: $color-text-accent;
    word-break: break-word;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  &__text {
    margin: 0;
    padding: 0;

    color: $color-text-primary-1;
    word-break: break-word;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  &__regulation {
    max-width: 100%;
    width: max-content;
    margin-top: 15px;
    display: flex;
    align-items: center;

    .content {
      .regulation-name {
        word-break: break-word;
      }
    }

    @media (hover: hover) {
      &:hover {
        .content {
          .regulation-name {
            color: $color-interactive;
          }
        }
      }
    }

    &:active {
      .content {
        .regulation-name {
          color: $color-interactive;
        }
      }
    }

    .icon {
      margin-right: 5px;

      svg {
        flex-shrink: 0;
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      .regulation-name {
        color: $color-text-primary-1;
        font-weight: 400;
        line-height: 17px;
        font-size: 12px;
        word-break: break-word;

        transition: all 0.1s ease;
      }

      .regulation-date {
        color: $color-text-primary-2;
        font-size: 12px;
        line-height: 17px;
        font-weight: 400;
        word-break: break-word;
      }
    }
  }
}
</style>
